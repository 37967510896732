<template>
  <div>
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div class="relative text-gray-600">
          <input
            v-model="searchString"
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-gray-100
              h-7
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
          />
          <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
      <ul class="flex items-center">
        <!-- <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            text-sm
            py-1
            rounded-lg
            px-3
            hover:bg-black
            mr-2
            flex
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 fill-current mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M17 9.5H3M21 4.5H3M21 14.5H3M17 19.5H3" />
          </svg>

          Sort
        </li> -->

        <!-- <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            text-sm
            py-1
            rounded-lg
            px-3
            hover:bg-black
            mr-2
            flex
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 fill-current mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="4" y1="21" x2="4" y2="14"></line>
            <line x1="4" y1="10" x2="4" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="3"></line>
            <line x1="20" y1="21" x2="20" y2="16"></line>
            <line x1="20" y1="12" x2="20" y2="3"></line>
            <line x1="1" y1="14" x2="7" y2="14"></line>
            <line x1="9" y1="8" x2="15" y2="8"></line>
            <line x1="17" y1="16" x2="23" y2="16"></line>
          </svg>
          Filter
        </li> -->

        <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
          @click="NewCustomerCompanyModalVisible = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New Customer Company
        </li>
      </ul>
    </div>
  </div>
  <!-- <NewInquiryModal
    @overlayClicked="newInquiryOverlayClicked"
    :isVisible="newInquiryVisible"
  ></NewInquiryModal> -->

  <NewCustomerCompanyModal
    @customerCompanyAdded="addCustomerCompany"
    @overlayClicked="NewCustomerCompanyModalVisible = false"
    :isVisible="NewCustomerCompanyModalVisible"
  />

  <div class="inq-table">
    <div>
      <div class="flex flex-col">
        <!-- There was flex grow here -->
        <div class="flex-grow relative">
          <!-- <SearchLoadingOverlay v-if="loading"></SearchLoadingOverlay> -->
          <table class="relative w-full border">
            <thead class="w-full border border-t-o border-r-0 border-l-0">
              <tr class="text-left">
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                    pl-2
                  "
                >
                  <input
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-gray-600"
                  />
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  CODE
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  NAME
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  CR LIMIT
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  DATE
                </th>
                <th
                  class="
                    top-0
                    px-1
                    py-2
                    text-gray-600
                    bg-white
                    text-xs
                    hover:bg-gray-100
                  "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody class="divide-y text-xs">
              <!-- ADD INQUIRY ITEM -->
              <!-- <InquiryTableItem
                :inquiry="inquiry"
                v-for="inquiry in inquiries"
                :key="inquiry._id"
              ></InquiryTableItem> -->
              <CustomerCompanyTableItem
                v-for="company in customerCompanies"
                :key="company._id"
                :company="company"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewCustomerCompanyModal from "@/components/modules/customer-companies/NewCustomerCompanyModal";
import CustomerCompanyTableItem from "@/components/modules/customer-companies/CustomerCompanyTableItem";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "Customers",
  data() {
    return {
      searchString: "",
      NewCustomerCompanyModalVisible: false,
    };
  },
  methods: {
    ...mapActions(["addCustomerCompany", "getCustomerCompanies"]),

    handleAddCustomer(customer) {
      console.log(customer);
      this.addCustomer(customer);
    },
  },
  computed: {
    ...mapGetters(["customerCompanies"]),
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },

    topBarLeftMargin() {
      this.sidebarCollapsed;

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  mounted() {
    this.getCustomerCompanies();
  },
  components: {
    CustomerCompanyTableItem,
    NewCustomerCompanyModal,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}

.sidebar {
  width: 16.5%;
}

.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
  th {
    position: sticky;
    z-index: 48;
    top: 5.75rem;
  }
}
</style>